import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Medium} from '../../classes/medium';
import {FormType} from '../../enum/form-type-enum';
import {IFormData} from '../../interfaces/form-data';
import {ImageType} from '../../enum/image-type-enum';
import {CSS_LAYOUT_CONFIG} from '../../consts';
import {MessageBoxTypeEnum} from '../../enum/message-box-type-enum';
import {VisualComponent} from '../../classes/visual-component';

@Component({
  selector: 'app-form-image-loader',
  templateUrl: './form-image-loader.component.html',
  styleUrls: ['./form-image-loader.component.scss']
})
export class FormImageLoaderComponent implements OnInit {
  @Input() entity: Medium | VisualComponent;
  @Input() imageType: ImageType;
  @Output() imageEvent = new EventEmitter<IFormData<Medium | VisualComponent>>();
  selectedImage?: File;
  label = 'Select Image';
  selectedFileName = '';
  imagesUrl: string;
  config = CSS_LAYOUT_CONFIG.responsiveImageDimensions;
  MessageBoxType = MessageBoxTypeEnum;
  errorMessage: string;

  constructor() {
    this.imagesUrl = environment.imagesUrl;
    this.selectedFileName = this.label;
  }
  ngOnInit(): void {}

  onSelectFile(event: any): void {
    this.errorMessage = null;
    if (event.target.files && event.target.files[0]) {
      this.selectedImage = event.target.files[0];
      this.selectedFileName = this.selectedImage.name;
    } else {
      this.selectedFileName = this.label;
    }
  }

  onUpload(): void {
    if (this.selectedImage) {
      try {
        this.checkFileSize(this.selectedImage, this.config.imageUploadMaxSize);
        this.checkFileType(this.selectedImage, this.config.imageUploadTypes);

        const data = {formType: FormType.INSERT, obj: this.entity, image: this.selectedImage, imageType: this.imageType} as IFormData<Medium | VisualComponent>;
        this.imageEvent.emit(data);
      } catch (error) {
        if (error instanceof Error) {
          this.errorMessage = error.message;
        } else {
          this.errorMessage = error.toString();
        }
      }
    }
  }

  checkFileSize(file: File, maxSize: number): void {
    const size = Math.round(file.size / 1000);
    if (size > maxSize) {
      const message = `Only images with a maximum size of ${maxSize} kb may be uploaded. The current image has ${size} kb.`;
      throw new Error(message);
    }
  }

  checkFileType(file: File, allowedImageTypes: string[]): void {
    let valid = false;
    allowedImageTypes.forEach(type => {
        if (file.type.search(type) >= 0) {
          valid = true;
        }
    });

    if (! valid) {
      const message = `Uploaded file has not a valid image type. Only ${allowedImageTypes.join(', ')} files are allowed.`;
      throw new Error(message);
    }
  }
}
